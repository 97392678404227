<template>
  <div class="goodsSpecList">
    <div class="goodsSpecItem" v-for="(item, index) in goodsUnit" :key="index">
      <div class="left">
        <!-- 表格区域 -->
        <el-table
          :data="item.goodsUnits"
          style="width: 100%"
          border
          class="table"
        >
          <el-table-column align="center" label="序号" width="55">
            <template>
              <div>{{ index + 1 }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="是否上架" min-width="100">
            <template slot-scope="scope">
              <el-switch
                :disabled="updateDisabled"
                v-model="scope.row.status"
                :active-value="0"
                :inactive-value="1"
              >
              </el-switch>
              <!-- 上架状态(0 上架、1 下架) -->
            </template>
          </el-table-column>
          <el-table-column
            prop="barcode"
            align="center"
            label="商品条码"
            min-width="100"
          >
          </el-table-column>
          <el-table-column
            prop="goodsSpec"
            align="center"
            label="规格"
            min-width="100"
          >
          </el-table-column>
          <el-table-column
            prop="unitName"
            align="center"
            label="单位"
            min-width="100"
          >
          </el-table-column>
          <el-table-column
            prop="salePrice"
            align="center"
            label="销售价格(元)"
            min-width="100"
          >
            <template slot="header">
              <i style="color: #ff4949">*</i>
              销售价格(元)
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="`goodsUnit[${index}].goodsUnits[${scope.$index}].salePrice`"
                :rules="
                  updateDisabled
                    ? undefined
                    : rules[`goodsUnit.goodsUnits.salePrice`]
                "
              >
                <el-input
                  size="mini"
                  v-model="scope.row.salePrice"
                  placeholder="销售价格(元)"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            v-if="type != 'dianCan'"
            prop="liningPrice"
            align="center"
            label="划线价格(元)"
            min-width="100"
          >
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.liningPrice"
                placeholder="划线价格(元)"
              ></el-input>
            </template>
          </el-table-column>

          <el-table-column
            v-if="type != 'dianCan'"
            align="center"
            label="是否使用实时库存"
            min-width="100"
          >
            <template slot-scope="scope">
              <el-switch
                :disabled="updateDisabled"
                v-model="scope.row.stockUseMode"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
              <!-- ( 0 虚拟库存 1 实时库存)-->
            </template>
          </el-table-column>
          <el-table-column
           v-if="type != 'dianCan'"
            prop="stockQuantity"
            align="center"
            label="库存"
            min-width="100"
          >
            <template slot="header">
              <i style="color: #ff4949">*</i>
              虚拟库存
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="`goodsUnit[${index}].goodsUnits[${scope.$index}].stockQuantity`"
                :rules="
                  updateDisabled || scope.row.stockUseMode == 1
                    ? undefined
                    : rules[`goodsUnit.goodsUnits.stockQuantity`]
                "
              >
                <el-input
                  size="mini"
                  :disabled="scope.row.stockUseMode == 1"
                  v-model="scope.row.stockQuantity"
                  placeholder="请输入库存"
                />
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
        <!-- 商品属性列表 -->
        <el-form-item label="商品属性:" label-width="75px" class="marT10">
          <div class="machiningList">
            <div
              class="machiningItem marT10"
              v-for="(v, i) in item.machiningGroups"
              :key="i"
            >
              <div class="x-f">
                <el-select
                  v-model="v.machiningGroupId"
                  size="mini"
                  placeholder="请选择"
                  @focus="filterMachiningData(index)"
                  @change="changeMachiningData(index, i)"
                >
                  <el-option
                    v-for="item in curMachiningData"
                    :key="item.machiningGroupId"
                    :label="item.machiningGroupName"
                    :value="item.machiningGroupId"
                  >
                  </el-option>
                </el-select>
                <el-button
                  class="marL10"
                  type="text"
                  @click="handleMachiningGroup('del', index, i)"
                  >删除
                </el-button>
              </div>
              <div class="marT10 x-fs">
                <el-tag
                  v-for="(j, x) in v.machiningItems"
                  :key="j.machiningId"
                  class="marR10 marB10"
                  type="info"
                  color="#f2f2f2"
                  closable
                  @close="handleMachining('del', index, i, x)"
                >
                  {{ j.machiningName + " ￥" + j.machiningPrice }}
                </el-tag>
                <el-button
                  class="addMachiningBtn"
                  size="mini"
                  icon="el-icon-plus"
                  v-if="v.machiningGroupId"
                  @click="
                    handleMachining('add', index, i, '', v.machiningGroupId)
                  "
                ></el-button>
              </div>
            </div>
          </div>
          <el-button
            size="mini"
            class="el-icon-plus"
            @click="handleMachiningGroup('add', index)"
            >添加属性
          </el-button>
        </el-form-item>
        <!-- 搭配商品 -->
        <el-form-item label="搭配商品:" label-width="75px" class="marT10">
          <el-button
            size="mini"
            v-if="item.collocationItems.length <= 0"
            class="el-icon-plus"
            @click="handleCollocationTableRow('push', index, 0)"
            >添加商品
          </el-button>
        </el-form-item>
        <!-- 搭配商品表格 -->
        <el-table
          v-if="item.collocationItems.length > 0"
          :data="item.collocationItems"
          style="width: 100%"
          border
          class="collocationTable marT10"
          @cell-mouse-enter="cellMouseEnter($event, index)"
          @cell-mouse-leave="cellMouseLeave(index)"
        >
          <el-table-column align="center" width="80">
            <template v-slot="scope">
              <i
                v-if="scope.row.hoverRow"
                @click="handleCollocationTableRow('push', index, scope.$index)"
                class="el-icon-circle-plus operatePush"
              ></i>
              <i
                v-if="scope.row.hoverRow"
                @click="handleCollocationTableRow('del', index, scope.$index)"
                class="el-icon-remove operateDel"
              ></i>
              <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="商品编码"
            min-width="180"
            align="center"
            prop="goodsNo"
          >
            <template slot="header">
              <i style="color: #ff4949">*</i>
              商品编码
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="`goodsUnit[${index}].collocationItems[${scope.$index}].goodsNo`"
                :rules="rules[`goodsUnit.collocationItems.goodsNo`]"
              > 
              <SelectRemote
                  v-if="type==='cake'"
                  size="mini"
                  v-model="scope.row.goodsNo"
                  :option="
                    $select({
                      key: 'cakeDialog',
                      option: {
                        option: {
                          noEcho: true,
                          remoteBody: { status: 0, goodsType: 1 },
                          change: (propValue, row) => {
                            if (propValue) {
                              const findIndex = item.collocationItems.findIndex(
                                (item) => item.goodsNo === propValue
                              );
                              let rows = JSON.parse(JSON.stringify(row));
                              rows.findIndex = findIndex;
                              getGoodS([rows], index);
                            } else {
                              this.$nextTick(() => {
                                console.log('11');
                              });
                            }
                          },
                          tableChange: (propValue, row) => {
                            getGoodS(row.check, index);
                          },
                          buttons: [
                            {
                              type: 'more',
                              option: {
                                title: '选择商品',
                                width: 1250,
                                type: 'TreeAndTable',
                                formData: $dialog({
                                  key: 'cakeGoodsDialog',
                                  option: {
                                    // treeApi: undefined,
                                    queryParams: { status: 0, goodsType: 1 },
                                    table: {
                                      ...$dialog({ key: 'cakeGoodsDialog' }).table,
                                    },
                                  },
                                }),
                              },
                            },
                          ],
                        },
                      },
                    }).option
                  "
                  :filterable="true"
                  placeholder="选择商品"
                />
                <SelectRemote
                  v-else-if="type==='dianCan'"
                  size="mini"
                  v-model="scope.row.goodsNo"
                  :option="
                    $select({
                      key: 'listDianCan',
                      option: {
                        option: {
                          noEcho: true,
                          remoteBody: { status: 0, goodsType: 1 },
                          change: (propValue, row) => {
                            if (propValue) {
                              const findIndex = item.collocationItems.findIndex(
                                (item) => item.goodsNo === propValue
                              );
                              let rows = JSON.parse(JSON.stringify(row));
                              rows.findIndex = findIndex;
                              getGoodS([rows], index);
                            } else {
                              this.$nextTick(() => {
                                console.log('11');
                              });
                            }
                          },
                          tableChange: (propValue, row) => {
                            getGoodS(row.check, index);
                          },
                          buttons: [
                            {
                              type: 'more',
                              option: {
                                title: '选择商品',
                                width: 1250,
                                type: 'TreeAndTable',
                                formData: $dialog({
                                  key: 'dianCanGoods',
                                  option: {
                                    // treeApi: undefined,
                                    queryParams: { status: 0, goodsType: 1 },
                                    table: {
                                      ...$dialog({ key: 'dianCanGoods' }).table,
                                    },
                                  },
                                }),
                              },
                            },
                          ],
                        },
                      },
                    }).option
                  "
                  :filterable="true"
                  placeholder="选择商品"
                />
                <SelectRemote
                  v-else
                  size="mini"
                  v-model="scope.row.goodsNo"
                  :option="
                    $select({
                      key: 'o2oGoods',
                      option: {
                        option: {
                          noEcho: true,
                          remoteBody: { status: 0, goodsType: 1 },
                          change: (propValue, row) => {
                            if (propValue) {
                              const findIndex = item.collocationItems.findIndex(
                                (item) => item.goodsNo === propValue
                              );
                              let rows = JSON.parse(JSON.stringify(row));
                              rows.findIndex = findIndex;
                              getGoodS([rows], index);
                            } else {
                              this.$nextTick(() => {
                                console.log('11');
                              });
                            }
                          },
                          tableChange: (propValue, row) => {
                            getGoodS(row.check, index);
                          },
                          buttons: [
                            {
                              type: 'more',
                              option: {
                                title: '选择商品',
                                width: 1250,
                                type: 'TreeAndTable',
                                formData: $dialog({
                                  key: 'o2oGoods',
                                  option: {
                                    // treeApi: undefined,
                                    queryParams: { status: 0, goodsType: 1 },
                                    table: {
                                      ...$dialog({ key: 'o2oGoods' }).table,
                                    },
                                  },
                                }),
                              },
                            },
                          ],
                        },
                      },
                    }).option
                  "
                  :filterable="true"
                  placeholder="选择商品"
                />
                <!-- <el-input
                  size="mini"
                  v-model="scope.row.goodsNo"
                  @keyup.enter.native="
                    collocationTableKeyup($event, index, scope.$index)
                  "
                >
                  <i
                    slot="suffix"
                    class="el-icon-more more"
                    @click="openSelectGoods(index)"
                  ></i>
                </el-input> -->
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            label="商品名称"
            align="center"
            prop="goodsName"
            show-overflow-tooltip
            min-width="120"
          >
          </el-table-column>
          <el-table-column
            label="商品条码"
            min-width="200"
            align="center"
            prop="barcode"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            label="规格"
            min-width="120"
            align="center"
            prop="goodsSpec"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            label="单位"
            min-width="120"
            align="center"
            prop="unitId"
            show-overflow-tooltip
          >
            <template slot="header">
              <i style="color: #ff4949">*</i>
              单位
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="`goodsUnit[${index}].collocationItems[${scope.$index}].unitId`"
                :rules="rules[`goodsUnit.collocationItems.unitId`]"
              >
                <el-select
                  size="mini"
                  v-model="scope.row.unitId"
                  @blur="
                    $parent.$parent.validateField(
                      `goodsUnit[${index}].collocationItems[${scope.$index}].unitId`
                    )
                  "
                  @change="changeUnitPrice(scope.row)"
                >
                  <el-option
                    v-for="item in scope.row.units"
                    :key="item.unitId"
                    :label="item.unitName"
                    :value="item.unitId"
                  />
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            label="标准售价"
            min-width="120"
            align="center"
            prop="salePrice"
          >
          </el-table-column>
          <el-table-column
            label="数量"
            align="center"
            prop="goodsQty"
            min-width="100"
          >
            <template slot="header">
              <i style="color: #ff4949">*</i>
              数量
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="`goodsUnit[${index}].collocationItems[${scope.$index}].goodsQty`"
                :rules="rules[`goodsUnit.collocationItems.goodsQty`]"
              >
                <el-input size="mini" v-model="scope.row.goodsQty"> </el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            label="搭配价"
            align="center"
            prop="goodsPrice"
            min-width="100"
          >
            <template slot="header">
              <i style="color: #ff4949">*</i>
              搭配价
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="`goodsUnit[${index}].collocationItems[${scope.$index}].goodsPrice`"
                :rules="rules[`goodsUnit.collocationItems.goodsPrice`]"
              >
                <el-input size="mini" v-model="scope.row.goodsPrice">
                </el-input>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- <div class="right" v-if="index !== 0" @click="delGoodsSpec(index)">
                <i class="el-icon-circle-close"></i>
            </div> -->
    </div>
  </div>
</template>

<script>
import {
  getTableSelect, //下拉框
  getSelectAssign, //赋值
} from "@/utils/orderTableSelect.js"; //表格的下拉框公共配置
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
export default {
  name: "GoodsSpecItem",
  components: {
    SelectRemote,
  },
  props: {
    //规格数组
    goodsUnit: {
      type: Array,
    },
    //属性数据
    machiningData: {
      type: Array,
    },
    //单位列表数据
    unitList: {
      type: Array,
    },
    //换算单位数据
    referUnitData: {
      type: Array,
    },
    // 是否启用门店价格策略
    updateDisabled: {
      type: Boolean,
      default: true,
    },
    //类型
    type: {
      type: String,
      default: "",
    },
  },
  computed: {
    // //商品下拉框配置项
    // optionGoods () {
    //   var that = this
    //   return function (row, collocationItems) {
    //     return getTableSelect(
    //       {
    //         billDetailItems: collocationItems,
    //         getMultipleGoods: this.getGoodS,
    //         row
    //       },
    //       that
    //     )
    //   }
    // }
  },
  data() {
    return {
      //当前商品规格的属性数据
      curMachiningData: [],
      //表单校验
      rules: {
        // 商品规格表格
        // 'goodsUnit.goodsUnits.barcode': [
        //     { required: true, message: '请输入条码', trigger: ['blur', 'change'] }
        // ],
        "goodsUnit.goodsUnits.salePrice": [
          {
            required: true,
            message: "请输入销售价格",
            trigger: ["blur", "change"],
          },
        ],
        "goodsUnit.goodsUnits.stockQuantity": [
          {
            required: true,
            message: "请输入库存",
            trigger: ["blur", "change"],
          },
        ],
        // 'goodsUnit.goodsUnits.unitName': [
        //     { required: true, message: '请选择单位', trigger: ['blur', 'change'] }
        // ],
        // 'goodsUnit.goodsUnits.referUnitAmount': [
        //     {
        //         required: true,
        //         message: '请输入换算数量',
        //         trigger: ['blur', 'change']
        //     }
        // ],
        // 'goodsUnit.goodsUnits.referUnitId': [
        //     {
        //         required: true,
        //         message: '请选择换算单位',
        //         trigger: ['blur', 'change']
        //     }
        // ],
        //搭配商品表格
        "goodsUnit.collocationItems.goodsNo": [
          {
            required: true,
            message: "请选择商品",
            trigger: ["blur", "change"],
          },
        ],
        "goodsUnit.collocationItems.unitId": [
          {
            required: true,
            message: "请选择单位",
            trigger: ["blur", "change"],
          },
        ],
        "goodsUnit.collocationItems.goodsQty": [
          {
            required: true,
            message: "请输入数量",
            trigger: ["blur", "change"],
          },
        ],
        "goodsUnit.collocationItems.goodsPrice": [
          {
            required: true,
            message: "请输入搭配价",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  watch: {
    //侦听传过来的属性数据 赋值当前规格属性数组
    machiningData: {
      handler(val) {
        if (val) this.curMachiningData = val;
      },
      deep: true,
    },
    goodsUnit: {
      handler(nVal) {
        let arr = [];
        for (let i = 0; i < nVal.length; i++) {
          arr.push(nVal[i].goodsUnits[0]);
        }
        // console.log('arr', arr)
        this.$emit("setShopPriceItems", arr);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    //选中的商品
    getGoodS(value, index) {
      let rows = JSON.parse(JSON.stringify(value));
      if (this.type == 'cake'){
        rows = rows.map((item) => (
          {
            ...item,
            units: item.goodsUnits,
            unitId: item.goodsUnits[0].unitId,
            barcode: item.goodsUnits[0].barcode,
            salePrice: item.goodsUnits[0].salePrice,
          }
        ));
      } else {
        rows.forEach((v) => {
          this.$set(v, "unitQty", "");
          this.$set(v, "unitPrice", 0);
          this.$set(v, "unitMoney", 0);
          v.unitPrice = v.purPrice;
          v.remark = "";
          v.unitMoney = v.unitQty * v.unitPrice;
          v.hoverRow = false;
        });
      }
      // this.goodsUnit[0].collocationItems.push(...value)
      let findIndex = rows[0].findIndex;
      if (rows.length == 1 && findIndex != undefined) {
        console.log(rows[findIndex]);
        this.goodsUnit[index].collocationItems[findIndex] = rows[0];
      } else {
        this.goodsUnit[index].collocationItems.push(...rows);
      }
      this.goodsUnit[index].collocationItems = this.goodsUnit[
        index
      ].collocationItems.filter(
        (item) => item.goodsNo != "" && item.goodsNo != undefined
      );
    },
    //操作属性组(新增/删除)  type新增/删除  specListIndex 规则列表下标   MGIndex 属性组下标
    handleMachiningGroup(type, specListIndex, MGIndex) {
      let obj = {
        type: type,
        specListIndex: specListIndex,
      };
      if (MGIndex) obj.MGIndex = MGIndex;
      this.$emit("handleAddMachiningGroup", obj);
    },
    //操作属性(新增/删除)  type新增/删除  specListIndex 规则列表下标 MGIndex 属性组下标  MIndex 属性下标  id 属性组id
    handleMachining(type, specListIndex, MGIndex, MIndex, id) {
      let obj = {
        type: type,
        specListIndex: specListIndex,
        MGIndex: MGIndex,
      };
      if (MIndex) obj.MIndex = MIndex;
      if (id) obj.id = id;
      this.$emit("handleAddMachining", obj);
      //删除属性组时,
    },
    //删除商品规格  index规格列表下标
    delGoodsSpec(index) {
      this.$emit("delGoodsSpec", index);
    },
    //设置单位
    setUnit() {
      this.$emit("setUnit");
    },
    //刷新换算单位数据   index当前规格数组下标
    refreshReferUnitData(index) {
      this.$emit("refreshReferUnitData", index);
    },
    //更改单位 如果该多规格被引用提醒用户是否统一更改
    changeUnit(row) {
      //遍历判断该单位是否被换算单位引用
      let bool = this.goodsUnit.every(
        (v) => v.goodsUnits[0].referUnitId !== row.unitId
      );
      //该单位被引用的换算单位统一更改  并提醒用户
      if (!bool) {
        this.referUnitData.map((item) => {
          if (item.referUnitId === row.unitId) item.unitName = row.unitName;
        });
        this.$message.success("该单位被引用的规格已统一更改");
      }
    },
    //过滤当前规格属性数组中已选中的属性  index当前规格下标
    filterMachiningData(index) {
      this.curMachiningData = this.machiningData.filter((item) => {
        let bool = this.goodsUnit[index].machiningGroups.every(
          (v) => item.machiningGroupId !== v.machiningGroupId
        );
        return bool;
      });
    },
    changeMachiningData(index, i) {
      try {
        this.goodsUnit[index].machiningGroups[i].machiningItems = [];
      } catch (error) {}
    },
    //搭配商品表格
    //表格行hover时,显示操作加减号
    cellMouseEnter(row, index) {
      //获取当前行下标
      let eleIndex = this.goodsUnit[index].collocationItems.indexOf(row);
      // 注意必须是使用两次深拷贝 因为 hoverRow 属性不是tableData原有的 则直接修改无效  所以两次深拷贝重新赋值
      let Arr = JSON.parse(
        JSON.stringify(this.goodsUnit[index].collocationItems)
      );
      for (let index = 0; index < Arr.length; index++) {
        const element = Arr[index];
        if (eleIndex == index) {
          element["hoverRow"] = true;
        } else {
          element["hoverRow"] = false;
        }
      }
      this.goodsUnit[index].collocationItems = JSON.parse(JSON.stringify(Arr));
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave(index) {
      // 移除hover的事件
      for (let i = 0; i < this.goodsUnit[index].collocationItems.length; i++) {
        const element = this.goodsUnit[index].collocationItems[i];
        element["hoverRow"] = false;
      }
    },
    //打开选择商品弹窗
    openSelectGoods(index) {
      this.$emit("openSelectGoods", index);
    },
    //商品单位更改 售价跟着改变  (搭配商品表格)
    changeUnitPrice(row) {
      let item = row.units.find((v) => v.unitId === row.unitId);
      row.salePrice = item.salePrice;
    },
    //操作搭配商品表格 type(增加行/减少行) specIndex规格列表下标  index当前表格行下标
    handleCollocationTableRow(type, specIndex, index) {
      let obj = {
        type: type,
        specIndex: specIndex,
        index: index,
      };
      this.$emit("handleCollocationTableRow", obj);
    },
    //搭配商品表格 商品编码键盘回车事件  event输入框值 specIndex规格列表下标  index当前表格行下标
    collocationTableKeyup(event, specIndex, index) {
      let obj = {
        val: event.target.value,
        specIndex: specIndex,
        index: index,
      };
      this.$emit("collocationTableKeyup", obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.goodsSpecList {
  width: 100%;

  .goodsSpecItem {
    display: flex;
    border-bottom: 3px dashed #ccc;
    padding: 15px 0;
    margin-right: 10px;

    .left {
      width: calc(100% - 50px);

      //属性名称tag
      .el-tag.el-tag--info {
        color: #333;
      }

      //表格(规格商品表格)
      .table {
        //使表格校验时显示，校验通过时隐藏
        ::v-deep .el-form-item {
          padding: 0;
          margin: 0;
        }

        ::v-deep .el-form-item__error {
          position: static;
        }

        //换算单位
        .referUnit {
          text-align: center;

          .el-input,
          .el-select {
            width: 100px;
            margin: 0 2px;
          }

          //图标
          span {
            margin: 5px;
          }

          //去掉下拉框小三角图标
          ::v-deep .el-input.is-disabled .el-input__icon {
            display: none !important;
          }
        }
      }

      //搭配商品表格
      .collocationTable {
        //使表格校验时显示，校验通过时隐藏
        ::v-deep .el-form-item {
          padding: 0;
          margin: 0;
        }

        ::v-deep .el-form-item__error {
          position: static;
        }
      }
    }

    .right {
      width: 50px;

      i {
        font-size: 25px;
        padding: 10px;
        margin: 5px;
        cursor: pointer;
        color: #ff3753;
      }
    }
  }
}
</style>
